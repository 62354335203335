import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cashier: "",
    cashierURL: "https://think-et.abimabusiness.com/graphql",
    gameNumber: "101",
    time: "240",
    timePercent: "0",
    odds: [],
    percent: "",
    nubersTicketToAnalyze: "",
    othersTicketToAnalyze: "",
  },
  getters: {},
  mutations: {
    loginCashier(state, cashier) {
      state.cashier = cashier;
    },
    gameNumber(state, gameNumber) {
      state.gameNumber = gameNumber;
    },
    time(state, time) {
      state.time = time;
    },
    timePercent(state, timePercent) {
      state.timePercent = timePercent;
    },
    odds(state, odds) {
      state.odds = odds;
    },

    percent(state, percent) {
      state.percent = percent;
    },

    nubersTicketToAnalyze(state, nubersTicketToAnalyze) {
      state.nubersTicketToAnalyze = nubersTicketToAnalyze;
    },

    othersTicketToAnalyze(state, othersTicketToAnalyze) {
      state.othersTicketToAnalyze = othersTicketToAnalyze;
    },
  },
  actions: {
    loginCashier(context, cashier) {
      context.commit("loginCashier", cashier);
    },

    gameNumber(context, gameNumber) {
      context.commit("gameNumber", gameNumber);
    },

    time(context, time) {
      context.commit("time", time);
    },

    timePercent(context, timePercent) {
      context.commit("timePercent", timePercent);
    },

    odds(context, odds) {
      context.commit("odds", odds);
    },

    percent(context, percent) {
      context.commit("percent", percent);
    },

    nubersTicketToAnalyze(context, nubersTicketToAnalyze) {
      context.commit("nubersTicketToAnalyze", nubersTicketToAnalyze);
    },

    othersTicketToAnalyze(context, othersTicketToAnalyze) {
      context.commit("othersTicketToAnalyze", othersTicketToAnalyze);
    },
  },

  plugins: [createPersistedState()],
  modules: {},
});
