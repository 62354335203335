<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div v-if="$store.state.cashier != ''">
      <div
        v-if="
          partnerBalance.tempo_package == 0 ||
          partnerBalance.tempo_package >= 2 * partnerBalance.permanent_package
        "
        class="text-center mt-16 white--text"
      >
        <h2>Insufficient Balance, Please contact the Admin of this system</h2>
      </div>

      <div v-else>
        <div class="hidden-md-and-down">
          <v-app-bar elevation="0" app id="navBar" dark>
            <v-layout row wrap class="" style="font-size: 14px">
              <v-flex xs1 class="" v-if="currentGame.length > 0">
                <p>GAME# {{ currentGame[0].game_number }}</p>
              </v-flex>
              <v-flex xs2 class="mt-2 mr-2 pr-1">
                <p>
                  <v-progress-linear
                    v-model="timePercent"
                    height="8"
                  ></v-progress-linear>
                </p>
              </v-flex>
              <v-flex
                class="red--text"
                xs2
                v-if="minutes == '00' && parseInt(seconds) <= 20"
              >
                <p>GAME CLOSES IN {{ minutes }}:{{ seconds }}</p>
              </v-flex>

              <v-flex class="" xs2 v-else>
                <p>GAME CLOSES IN {{ minutes }}:{{ seconds }}</p>
              </v-flex>
              <v-flex xs1 class="pl-2">
                <v-btn outlined rounded x-small @click="verifyDialog = true">
                  <span class="text-capitalize">Verify</span>
                </v-btn>
              </v-flex>
              <v-flex xs1 class="pl-2">
                <v-btn outlined rounded x-small @click="cancelDialog = true">
                  <span class="text-capitalize">Cancel</span>
                </v-btn>
              </v-flex>

              <!-- <v-flex xs1 class="pl-2">
                <v-btn
                  :loading="ticketLoading"
                  outlined
                  rounded
                  x-small
                  @click="getLastTwoGames"
                >
                  <span class="text-capitalize">Tickets</span>
                </v-btn>
              </v-flex> -->
              <v-flex xs1 class="pl-2">
                <v-btn text x-small @click="tmhPopUp = true">
                  <span class="text-capitalize"
                    >TMH = {{ totalMoneyOnHand - totalMoneyPaid }}
                    <span style="font-size: 8px">ETB</span>
                  </span>
                </v-btn>
              </v-flex>
            </v-layout>

            <!-- <v-spacer></v-spacer> -->
            <v-btn small text @click="reportTicketDialog = true">
              <span class="mr-2 text-capitalize">Report ticket</span>
            </v-btn>
            <v-btn small text @click="logoutClicked">
              <span class="mr-2 text-capitalize">Logout</span>
            </v-btn>
          </v-app-bar>

          <v-layout row wrap class="ml-1 mt-0">
            <v-flex xs6 class="">
              <v-layout row wrap class="text-center mr-3" justify-space-around>
                <v-flex
                  xs1
                  v-for="number in 40"
                  :key="number"
                  class="ma-1 mt-0 mb-2"
                  id="numbers"
                >
                  <div class="pt-2 pb-2" id="selectedNum">
                    <a @click="selectNumber(number)">
                      <div class="circle pt-1">
                        <span class="pa-1">{{ number }}</span>
                      </div>
                    </a>
                  </div>
                </v-flex>
                <v-flex xs12>
                  <br />
                </v-flex>
                <v-flex
                  xs1
                  v-for="number in 40"
                  :key="number + 40"
                  class="ma-1"
                  id="numbers"
                  k
                >
                  <div class="pt-2 pb-2" id="selectedNum">
                    <a @click="selectNumber(number + 40)">
                      <div class="circle pt-1">
                        <span class="pa-1">{{ number + 40 }}</span>
                      </div>
                    </a>
                  </div>
                </v-flex>

                <v-flex xs6 class="mt-2">
                  <v-layout row wrap class="">
                    <v-flex xs4 class="ma-1 text-center" id="numbers">
                      <div
                        v-if="selectedEvenOddAndHighLow.indexOf('Even') == -1"
                        class="pt-2 pb-2"
                        id="selectedNum"
                      >
                        <a @click="selectEvenOddAndHighLow('Even')">
                          <div>
                            <span class="pa-1 white--text">Even</span>
                          </div>
                        </a>
                      </div>

                      <div
                        v-if="selectedEvenOddAndHighLow.indexOf('Even') != -1"
                        class="pt-2 pb-2 red"
                        id="selectedNum"
                      >
                        <a @click="selectEvenOddAndHighLow('Even')">
                          <div>
                            <span class="pa-1 white--text">Even</span>
                          </div>
                        </a>
                      </div>
                    </v-flex>
                    <v-flex xs4 class="ma-1 text-center" id="numbers">
                      <div
                        v-if="selectedEvenOddAndHighLow.indexOf('Odd') == -1"
                        class="pt-2 pb-2"
                        id="selectedNum"
                      >
                        <a @click="selectEvenOddAndHighLow('Odd')">
                          <div>
                            <span class="pa-1 white--text">Odd</span>
                          </div>
                        </a>
                      </div>

                      <div
                        v-if="selectedEvenOddAndHighLow.indexOf('Odd') != -1"
                        class="pt-2 pb-2 red"
                        id="selectedNum"
                      >
                        <a @click="selectEvenOddAndHighLow('Odd')">
                          <div>
                            <span class="pa-1 white--text">Odd</span>
                          </div>
                        </a>
                      </div>
                    </v-flex>
                    <v-flex xs4 class="ma-1 text-center" id="numbers">
                      <div
                        v-if="selectedEvenOddAndHighLow.indexOf('High') == -1"
                        class="pt-2 pb-2"
                        id="selectedNum"
                      >
                        <a @click="selectEvenOddAndHighLow('High')">
                          <div>
                            <span class="pa-1 white--text">High</span>
                          </div>
                        </a>
                      </div>

                      <div
                        v-if="selectedEvenOddAndHighLow.indexOf('High') != -1"
                        class="pt-2 pb-2 red"
                        id="selectedNum"
                      >
                        <a @click="selectEvenOddAndHighLow('High')">
                          <div>
                            <span class="pa-1 white--text">High</span>
                          </div>
                        </a>
                      </div>
                    </v-flex>
                    <v-flex xs4 class="ma-1 text-center" id="numbers">
                      <div
                        v-if="selectedEvenOddAndHighLow.indexOf('Low') == -1"
                        class="pt-2 pb-2"
                        id="selectedNum"
                      >
                        <a @click="selectEvenOddAndHighLow('Low')">
                          <div>
                            <span class="pa-1 white--text">Low</span>
                          </div>
                        </a>
                      </div>

                      <div
                        v-if="selectedEvenOddAndHighLow.indexOf('Low') != -1"
                        class="pt-2 pb-2 red"
                        id="selectedNum"
                      >
                        <a @click="selectEvenOddAndHighLow('Low')">
                          <div>
                            <span class="pa-1 white--text">Low</span>
                          </div>
                        </a>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>

            <v-flex xs3>
              <v-layout
                row
                wrap
                class="mr-3"
                v-if="
                  selectedNumbers.length > 0 ||
                  selectedEvenOddAndHighLow.length > 0
                "
              >
                <!-- <v-flex xs12>
              <p style="font-size: 20px" class="white--text">
                Selected Numbers
              </p>
            </v-flex> -->
                <v-flex
                  xs12
                  class="grey mb-1 ml-0 pt-2 pb-2"
                  id="selectedNumbers"
                >
                  <v-layout row wrap justify-space-around>
                    <v-flex xs6 class="pl-8 pt-1">
                      <a
                        class="pa-1 pt-2 pb-3 white--text"
                        dark
                        @click="
                          selectedNumbers = [];
                          selectedEvenOddAndHighLow = [];
                          evenOddInputMoney = [];
                          selectedNumberOptionCounter = 0;
                          numberInputMoney = [];
                        "
                      >
                        <span class="text-capitalize">Clear</span></a
                      >
                    </v-flex>

                    <v-flex xs6 class="text-center pl-3">
                      <a class="pa-1 pt-2 pb-3" @click="recietOverview">
                        <v-icon large class="white--text">print</v-icon>
                      </a>
                    </v-flex>
                  </v-layout>

                  <!-- <v-btn
                id="printBtn"
                text
                dark
                class="pl-10 pr-10 red--text"
                v-if="selectedNumbers.length > 0"
                @click="selectedNumbers = []"
              >
                <span class="text-capitalize">Cancel</span>
              </v-btn> -->
                </v-flex>
                <v-flex
                  xs12
                  class="grey mb-1 ml-0 pt-1 pb-1"
                  id="selectedNumbers"
                  v-for="(selectedNumber, i) in selectedNumbers"
                  :key="i + 100"
                >
                  <v-layout row wrap justify-space-around>
                    <v-flex xs8 class="pt-2">
                      <span class="pa-1">{{ selectedNumber.toString() }}</span>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field
                        type="number"
                        min="0"
                        style="font-size: 11px"
                        v-model="numberInputMoney[i]"
                        class="white"
                        placeholder="Birr"
                        :rules="rules"
                        hide-details="true"
                        single-line
                        outlined
                        dense
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs1 class="pr-3">
                      <a @click="removeSelectedNumbers(i)"
                        ><v-icon color="error" right class="white"
                          >cancel</v-icon
                        ></a
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>

                <v-flex
                  xs12
                  class="text-right mb-1 ml-0 pt-1 pb-1 mt-1 mb-4"
                  v-if="selectedNumbers.length > 0"
                >
                  <a
                    class="pa-1 pt-2 pb-3"
                    style="border: 1px solid cyan; border-radius: 3px"
                    @click="
                      if (
                        selectedNumbers[selectedNumberOptionCounter].length > 0
                      ) {
                        selectedNumbers.push([]);
                        selectedNumberOptionCounter++;
                      }
                    "
                  >
                    <v-icon class="white--text">add</v-icon>
                  </a>
                </v-flex>

                <v-flex
                  xs12
                  class="grey mb-1 ml-0 pt-1 pb-1"
                  id="selectedNumbers"
                  v-for="(evenOdd, i) in selectedEvenOddAndHighLow"
                  :key="i"
                >
                  <v-layout row wrap justify-space-around>
                    <v-flex xs8 class="pt-2">
                      <span class="pa-1">{{ evenOdd }}</span>
                    </v-flex>

                    <v-flex xs2>
                      <v-text-field
                        type="number"
                        min="0"
                        style="font-size: 11px; color: black"
                        v-model="evenOddInputMoney[i]"
                        class="white"
                        placeholder="Birr"
                        :rules="rules"
                        hide-details="true"
                        single-line
                        outlined
                        dense
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs1 class="pr-3">
                      <a @click="removeEvenOddKenoList(evenOdd)"
                        ><v-icon color="error" right class="white"
                          >cancel</v-icon
                        ></a
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>

                <!-- <v-flex xs4 class="mt-10 text-center">
             
            </v-flex> -->
              </v-layout>
            </v-flex>

            <v-flex xs3>
              <v-layout row wrap class="text-center mr-1">
                <v-flex xs6 class="mt-0">
                  <a @click="applyToAll">
                    <v-card
                      dark
                      class="ma-1 mt-0 mb-2 pt-2 pb-2 pl-5 pr-5 customamount"
                      id="applyToAll"
                    >
                      <span>Apply to All</span>
                    </v-card>
                  </a>
                </v-flex>
                <v-flex xs6 class="mt-0">
                  <div class="ma-1 mt-0">
                    <v-text-field
                      v-model="selectedCustomAmount"
                      class="white"
                      placeholder="Birr"
                      :rules="rules"
                      hide-details="true"
                      single-line
                      outlined
                      dense
                    ></v-text-field>
                  </div>
                </v-flex>

                <v-flex
                  dark
                  xs4
                  v-for="amount in customAmount"
                  :key="amount + 600"
                >
                  <a @click="assignSelectedAmount(amount)">
                    <v-card
                      class="ma-1 mt-0 mb-2 pt-4 pb-4 pl-5 pr-5 customamount"
                      id="numbers"
                    >
                      <span>{{ amount }}</span>
                    </v-card>
                  </a>
                </v-flex>

                <v-flex dark xs4>
                  <a @click="clearAll">
                    <v-card
                      class="ma-1 mt-0 mb-2 pt-4 pb-4 pl-5 pr-5 customamount"
                      id="clearBtn"
                    >
                      <span>Clear</span>
                    </v-card>
                  </a>
                </v-flex>

                <v-flex xs12>
                  <v-card class="ma-1 pa-1 pt-0">
                    <div id="ticketListVertically">
                      <h4
                        v-if="isDuplicated"
                        class="red--text"
                        style="transform: scaleX(-1)"
                      >
                        Ticket Duplicated
                      </h4>
                      <v-form @submit.prevent="" style="transform: scaleX(-1)">
                        <v-card-text style="font-size: 11px">
                          <v-layout row wrap>
                            <v-flex
                              xs12
                              v-for="(lastGame, i) in lastTwoGames"
                              :key="i"
                            >
                              <v-layout
                                row
                                wrap
                                v-for="(ticket, j) in lastGame.getTickets"
                                :key="ticket.ticket_id"
                                justify-space-around
                              >
                                <v-flex
                                  xs12
                                  v-if="
                                    $store.state.cashier.cashier_id ==
                                    ticket.cashier_id
                                  "
                                >
                                  <span>
                                    Game number:
                                    {{ lastGame.game_number }} </span
                                  ><br />
                                  <h3>Tickets:</h3>
                                  <span>
                                    {{ ticket.choosen_numbers }}
                                  </span>
                                  <br />
                                  <span> {{ ticket.others }} </span>

                                  <barcode
                                    class=""
                                    :display-value="false"
                                    :value="ticket.user_id"
                                    height="35px"
                                  >
                                    Some thing went wrong.
                                  </barcode>
                                  <div
                                    class="text-right"
                                    v-if="lastGame.game_status == 'pending'"
                                  >
                                    <v-btn
                                      text
                                      small
                                      class="pink--text"
                                      @click="
                                        cancelDuplicatedTicket(
                                          ticket,
                                          lastGame.getTickets,
                                          j
                                        )
                                      "
                                    >
                                      <span class="text-capitalize"
                                        >Cancel ticket</span
                                      >
                                    </v-btn>
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-card-text>
                      </v-form>
                    </div>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <v-dialog
            v-model="printDialog"
            persistent
            max-width="450"
            v-if="currentGame.length > 0"
          >
            <v-card class="pt-5" ref="reciet">
              <v-card-text
                class=""
                id="printJS-form"
                ref="printable"
                style="font-size: 18px"
              >
                <div style="text-align: center">
                  <h1>K E N O</h1>

                  <br />
                </div>
                <div style="margin-left: 10px; color: black">
                  <div style="font-weight: bold; margin-left: 10px">
                    <span>
                      Cashier:
                      {{ this.$store.state.cashier.cashier_full_name }}
                    </span>
                    <br />
                    <span>
                      Shop#
                      {{ this.partnerBalance.shop_number }}
                    </span>
                    <br />

                    <span>Game#: {{ currentGame[0].game_number }}</span> <br />
                    <span>Date: {{ new Date(gameDate) | formatDate }}</span>
                  </div>
                  <hr style="border-top: 1px dashed" />

                  <table style="width: 100%; text-align: left">
                    <tr>
                      <th
                        style="
                          padding-bottom: 4px;
                          border-bottom: 1px solid grey;
                        "
                      >
                        Stake
                      </th>
                      <th
                        style="
                          padding-bottom: 4px;
                          border-bottom: 1px solid grey;
                        "
                      >
                        | &nbsp;&nbsp;Choosen
                      </th>
                      <th
                        style="
                          padding-bottom: 4px;
                          border-bottom: 1px solid grey;
                        "
                      >
                        | &nbsp;&nbsp;Odds
                      </th>
                      <th
                        style="
                          padding-bottom: 4px;
                          border-bottom: 1px solid grey;
                        "
                      >
                        | &nbsp;&nbsp;Win
                      </th>
                    </tr>

                    <span
                      v-for="(selectedNumber, i) in selectedNumbers"
                      :key="i + 1000"
                    >
                      <span
                        v-for="odds in $store.state.odds"
                        :key="odds.choosen_length"
                      >
                        <span
                          v-if="odds.choosen_length == selectedNumber.length"
                        >
                          <br />
                          <tr>
                            <td style="font-weight: bold; padding-top: 4px">
                              {{ numberInputMoney[i] }}
                            </td>
                            <td style="font-weight: bold; padding-top: 4px">
                              | &nbsp;&nbsp;{{ selectedNumber.toString() }}
                            </td>
                            <td style="font-weight: bold; padding-top: 4px">
                              | &nbsp;&nbsp;{{
                                parseFloat(odds.odd_value.split(",")[0]) / 10
                              }}
                            </td>
                            <td style="font-weight: bold; padding-top: 4px">
                              | &nbsp;&nbsp;{{
                                (parseFloat(numberInputMoney[i]) *
                                  parseFloat(odds.odd_value.split(",")[0])) /
                                10
                              }}
                            </td>
                          </tr>
                        </span>
                      </span>
                    </span>

                    <span
                      style="margin-top: 4px"
                      v-for="(other, i) in selectedEvenOddAndHighLow"
                      :key="i + 10001"
                    >
                      <span
                        v-for="odds in $store.state.odds"
                        :key="odds.choosen_length"
                      >
                        <span
                          v-if="
                            odds.choosen_length.toLowerCase() ==
                            other.toLowerCase()
                          "
                        >
                          <tr>
                            <td style="font-weight: bold; padding-top: 4px">
                              {{ evenOddInputMoney[i] }}
                            </td>
                            <td style="font-weight: bold; padding-top: 4px">
                              | &nbsp;&nbsp;{{ other }}
                            </td>
                            <td style="font-weight: bold; padding-top: 4px">
                              | &nbsp;&nbsp;{{ odds.odd_value }}
                            </td>
                            <td style="font-weight: bold; padding-top: 4px">
                              | &nbsp;&nbsp;{{
                                parseFloat(evenOddInputMoney[i]) *
                                parseFloat(odds.odd_value)
                              }}
                            </td>
                          </tr>
                        </span>
                      </span>
                    </span>
                  </table>

                  <table style="width: 100%">
                    <tr>
                      <td style="font-weight: bold; padding-top: 16px">
                        Total Stake
                      </td>
                      <td style="font-weight: bold; padding-top: 16px"></td>
                      <td style="font-weight: bold; padding-top: 16px"></td>
                      <td
                        style="
                          font-weight: bold;
                          padding-top: 16px;
                          text-align: center;
                        "
                      >
                        {{ totalInputMoney }}
                      </td>
                    </tr>

                    <tr>
                      <td style="font-weight: bold; padding-top: 8px">
                        Possible Win
                      </td>
                      <td style="font-weight: bold; padding-top: 8px"></td>
                      <td style="font-weight: bold; padding-top: 8px"></td>
                      <td
                        style="
                          font-weight: bold;
                          padding-top: 8px;
                          text-align: center;
                        "
                      >
                        {{ totalPossibleWin }}
                      </td>
                    </tr>
                  </table>

                  <br />
                </div>
                <div style="text-align: center; font-size: 11px">
                  Valid Until:
                  {{ new Date(gameDate + 48 * 60 * 60 * 1000) | formatDate }}
                </div>
                <div style="text-align: center">
                  <hr style="border-top: 1px dashed" />
                  <br />
                  <barcode :value="userNumber" height="70px">
                    Some thing went wrong.
                  </barcode>
                </div>

                <br />
                <div v-if="commonFeatures.length > 0">
                  <p
                    style="text-align: center; font-weight: bold"
                    v-if="commonFeatures.length > 0"
                  >
                    {{ commonFeatures[0].phone }} <br />
                    {{ commonFeatures[0].others }} <br />
                    {{ commonFeatures[0].brand_name }} <br />
                  </p>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :loading="printLoading"
                  color="green darken-1"
                  class="text-capitalize"
                  text
                >
                  Waiting...
                </v-btn>
                <v-btn
                  class="text-capitalize"
                  dark
                  color="red"
                  text
                  @click="printDialog = false"
                >
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="isPlaying" persistent max-width="420" class="red">
            <p
              class="headline white--text pink pa-5"
              style="border-radius: 5px"
            >
              In playing mode, please try later!
            </p>
          </v-dialog>

          <v-dialog v-model="verifyDialog" persistent max-width="420">
            <v-card class="text-center pt-5">
              <v-card-title> Verify user </v-card-title>
              <v-form @submit.prevent="">
                <v-card-text>
                  <P>Scan the Bar code or Enter user Id under the Bar code</P>
                  <v-text-field
                    autofocus
                    style="font-size: 11px"
                    v-model="userIDToVerify"
                    :rules="[(v) => !!v || 'Required']"
                    label="User ID or Bar code"
                    required
                    dense
                    outlined
                    prepend-icon="view_column"
                  ></v-text-field>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    :loading="verifyLoading"
                    color="green darken-1"
                    class="text-capitalize"
                    text
                    type="submit"
                    @click="verify"
                  >
                    verify
                  </v-btn>
                  <v-btn
                    class="text-capitalize"
                    dark
                    color="red"
                    text
                    @click="
                      verifyDialog = false;
                      userIDToVerify = '';
                    "
                  >
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>

          <v-dialog v-model="reportTicketDialog" persistent max-width="420">
            <v-card class="text-center pt-5">
              <v-card-title> Report ticket </v-card-title>
              <v-form @submit.prevent="">
                <v-card-text class="pl-5 pr-5">
                  <P>Scan the Bar code or Enter user Id under the Bar code</P>
                  <v-text-field
                    autofocus
                    style="font-size: 11px"
                    v-model="userIdForReport"
                    :rules="[(v) => !!v || 'Required']"
                    label="User ID or Bar code"
                    required
                    dense
                    outlined
                    hide-details="true"
                    prepend-icon="view_column"
                  ></v-text-field>

                  <v-text-field
                    class="mt-3"
                    hide-details="true"
                    style="font-size: 11px"
                    v-model="reportReason"
                    :rules="[(v) => !!v || 'Required']"
                    label="Write a reason"
                    required
                    dense
                    outlined
                    prepend-icon="note"
                  ></v-text-field>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    :loading="reportLoading"
                    color="green darken-1"
                    class="text-capitalize"
                    text
                    type="submit"
                    @click="reportTicket"
                  >
                    report
                  </v-btn>
                  <v-btn
                    class="text-capitalize"
                    dark
                    color="red"
                    text
                    @click="
                      reportTicketDialog = false;
                      userIdForReport = '';
                    "
                  >
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>

          <v-dialog v-model="tmhPopUp" persistent max-width="420">
            <v-card class="pt-5">
              <v-card-title> Total Money on Hand </v-card-title>
              <v-form @submit.prevent="">
                <v-card-text>
                  <P style="font-size: 18px"
                    >Total money: {{ totalMoneyOnHand }}
                    <span style="font-size: 14px">ETB</span></P
                  >

                  <P style="font-size: 18px"
                    >Total paid money: {{ totalMoneyPaid }}
                    <span style="font-size: 14px">ETB</span></P
                  >

                  <P style="font-size: 18px"
                    >Total money on hand:
                    {{ totalMoneyOnHand - totalMoneyPaid }}
                    <span style="font-size: 14px">ETB</span></P
                  >
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    class="text-capitalize"
                    dark
                    color="red"
                    text
                    @click="tmhPopUp = false"
                  >
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>

          <v-dialog v-model="logoutDialog" persistent max-width="420">
            <v-card class="pt-5">
              <v-form @submit.prevent="">
                <v-card-text
                  ref="printReport"
                  style="font-size: 14px; padding-left: 5px"
                >
                  <h2>Cashier Report</h2>
                  <br />
                  <h3>
                    Cashier Name:
                    {{ this.$store.state.cashier.cashier_full_name }}
                  </h3>

                  <h4>
                    Log in time:
                    {{
                      new Date(Number($store.state.cashier.loggin_time))
                        | formatDate
                    }}
                  </h4>
                  <h4>
                    Log out time:
                    {{ new Date(Number(Date.now())) | formatDate }}
                  </h4>
                  <h4>Location: {{ currentLocation }}</h4>
                  <h4>IP Address: {{ ipAddress }}</h4>

                  <br />
                  <span style="font-size: 18px"
                    >Total Tickets: {{ noTickets }} </span
                  ><br />

                  <span style="font-size: 18px"
                    >Paid Tickets: {{ noPaidTickets }}
                  </span>
                  <br />

                  <span style="font-size: 18px"
                    >Cancelled Tickets: {{ noCancelledTickets }}
                  </span>
                  <br />
                  <span style="font-size: 18px"
                    >Number of Bets: {{ noBets }}
                  </span>
                  <br /><br />
                  <span style="font-size: 18px"
                    >Total money: {{ totalMoneyOnHand }}
                    <span style="font-size: 14px">ETB</span></span
                  >
                  <br />

                  <span style="font-size: 18px"
                    >Total paid money: {{ totalMoneyPaid }}
                    <span style="font-size: 14px">ETB</span></span
                  >
                  <br />

                  <span style="font-size: 18px"
                    >Total money on hand:
                    {{ totalMoneyOnHand - totalMoneyPaid }}
                    <span style="font-size: 14px">ETB</span></span
                  >
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    :loading="logoutLoading"
                    class="text-capitalize pink--text"
                    text
                    @click="logout"
                  >
                    print report
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>

          <v-dialog v-model="ticketDialog" persistent max-width="420">
            <v-card class="">
              <v-card-title>
                Current game tickets <v-spacer></v-spacer>

                <v-btn
                  class="text-capitalize"
                  dark
                  color="red"
                  text
                  @click="ticketDialog = false"
                >
                  <v-icon>cancel</v-icon>
                </v-btn>
              </v-card-title>
              <v-form @submit.prevent="">
                <v-card-text>
                  <v-layout row wrap>
                    <v-flex xs12 v-for="(lastGame, i) in lastTwoGames" :key="i">
                      <v-layout
                        row
                        wrap
                        v-for="(ticket, j) in lastGame.getTickets"
                        :key="ticket.ticket_id"
                        justify-space-around
                      >
                        <v-flex
                          xs12
                          v-if="
                            $store.state.cashier.cashier_id == ticket.cashier_id
                          "
                        >
                          <span> Game number: {{ lastGame.game_number }} </span
                          ><br />
                          <h3>Tickets:</h3>
                          <span>
                            {{ ticket.choosen_numbers }}
                          </span>
                          <br />
                          <span> {{ ticket.others }} </span><br />
                          <h3>User ID:</h3>
                          <barcode :value="ticket.user_id" height="50px">
                            Some thing went wrong.
                          </barcode>
                          <div
                            class="text-right"
                            v-if="lastGame.game_status == 'pending'"
                          >
                            <v-btn
                              text
                              small
                              class="pink--text"
                              @click="
                                cancelDuplicatedTicket(
                                  ticket,
                                  lastGame.getTickets,
                                  j
                                )
                              "
                            >
                              <span class="text-capitalize">Cancel ticket</span>
                            </v-btn>
                          </div>
                        </v-flex>
                        <v-flex xs12 class="ma-2">
                          <hr />
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-form>
            </v-card>
          </v-dialog>

          <v-dialog v-model="winnersDialog" persistent max-width="420">
            <v-card class="text-center pt-5">
              <v-card-title> Check ticket </v-card-title>

              <v-card-text>
                <P class="green--text" v-if="totalWinMoneyForOneUser > 0">
                  <v-icon class="green--text">check</v-icon>
                  User won
                  <strong>{{ totalWinMoneyForOneUser }}</strong> Birr</P
                >
                <P class="red--text" v-else
                  ><v-icon class="red--text">cancel</v-icon> User loss the game!
                </P>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :loading="payLoading"
                  color="green darken-1"
                  class="text-capitalize"
                  text
                  type="submit"
                  v-if="totalWinMoneyForOneUser > 0"
                  @click="payWinnerAward"
                >
                  Pay
                </v-btn>
                <v-btn
                  class="text-capitalize"
                  dark
                  color="red"
                  text
                  @click="winnersDialog = false"
                >
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="cancelDialog" persistent max-width="420" top>
            <v-card class="text-center pt-5">
              <v-card-title> Cancel Ticket </v-card-title>

              <v-form @submit.prevent="">
                <v-card-text>
                  <P
                    >Scan the Bar code or Enter user Id under the Bar to cancel
                    the ticket</P
                  >
                  <v-text-field
                    autofocus
                    style="font-size: 11px"
                    v-model="userIDToCancel"
                    :rules="[(v) => !!v || 'Required']"
                    label="User ID or Bar code"
                    required
                    dense
                    outlined
                    prepend-icon="view_column"
                  ></v-text-field>

                  <P>Are you sure you want to cancel this ticket?</P>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    :loading="cancelLoading"
                    color="green darken-1"
                    class="text-capitalize"
                    text
                    type="submit"
                    @click="cancelTicket"
                  >
                    Yes
                  </v-btn>
                  <v-btn
                    class="text-capitalize"
                    dark
                    color="red"
                    text
                    @click="cancelDialog = false"
                  >
                    No
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </div>
        <div class="hidden-lg-only text-center white--text">
          <p>Not supported in small screens, please open in pc</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueBarcode from "vue-barcode";

import axios from "axios";

export default {
  components: {
    barcode: VueBarcode,
  },
  data() {
    return {
      timePercent: 0,
      timeInSeconds: 0,
      minutes: 5,
      seconds: 0,
      selectedNumbers: [],
      selectedNumberOptionCounter: 0,
      userIDToVerify: "",
      userIDToCancel: "",
      partnerBalance: "",
      ticketLoading: false,
      isDuplicated: false,
      logoutLoading: false,
      logoutDialog: false,
      printDialog: false,
      verifyDialog: false,
      printLoading: false,
      verifyLoading: false,
      winnersDialog: false,
      cancelDialog: false,
      payLoading: false,
      cancelLoading: false,
      reportTicketDialog: false,
      userIdForReport: "",
      reportReason: "",
      gameDate: "",
      userNumber: "",
      reportLoading: false,
      currentGame: [],
      noTickets: 0,
      noPaidTickets: 0,
      noCancelledTickets: 0,
      noBets: 0,

      odds: [],
      verifyUserData: [],
      selectedEvenOddAndHighLow: [],
      isPlaying: false,
      totalWinMoneyForOneUser: 0,

      rules: [(value) => !!value || "Required."],
      evenOddInputMoney: [],
      numberInputMoney: [],
      totalMoneyOnHand: 0,
      totalMoneyPaid: 0,
      tmhPopUp: false,
      lastTwoGames: [],
      commonFeatures: [],
      ticketDialog: false,
      customAmount: [250, 200, 150, 100, 50, 30, 20, 10],
      selectedCustomAmount: 0,
      currentLocation: "",
      ipAddress: "",
      totalInputMoney: 0,
      totalPossibleWin: 0,
      //style="border: 1px solid cyan; border-radius: 3px"
    };
  },

  methods: {
    assignSelectedAmount(amount) {
      this.selectedCustomAmount = amount;
      if (this.selectedNumbers.length > 0)
        this.numberInputMoney[this.selectedNumbers.length - 1] =
          this.selectedCustomAmount;

      if (this.selectedEvenOddAndHighLow.length > 0)
        this.evenOddInputMoney[this.selectedEvenOddAndHighLow.length - 1] =
          this.selectedCustomAmount;
    },

    applyToAll() {
      this.numberInputMoney = [];
      this.evenOddInputMoney = [];
      for (let i = 0; i < this.selectedNumbers.length; i++) {
        this.numberInputMoney.push(this.selectedCustomAmount);
      }

      for (let i = 0; i < this.selectedEvenOddAndHighLow.length; i++) {
        this.evenOddInputMoney.push(this.selectedCustomAmount);
      }
    },
    clearAll() {
      this.numberInputMoney = [];
      this.evenOddInputMoney = [];
      this.selectedCustomAmount = 0;
    },
    selectNumber(number) {
      if (this.selectedNumbers.length == 0) this.selectedNumbers.push([]);
      if (
        this.selectedNumbers[this.selectedNumberOptionCounter].indexOf(
          number
        ) != -1
      ) {
        this.selectedNumbers[this.selectedNumberOptionCounter].splice(
          this.selectedNumbers[this.selectedNumberOptionCounter].indexOf(
            number
          ),
          1
        );
      } else if (
        this.selectedNumbers[this.selectedNumberOptionCounter].length < 10
      )
        this.selectedNumbers[this.selectedNumberOptionCounter].push(number);
    },

    selectEvenOddAndHighLow(evenOddHighLow) {
      this.selectedEvenOddAndHighLow.push(evenOddHighLow);
    },

    removeEvenOddKenoList(item) {
      let index = this.selectedEvenOddAndHighLow.indexOf(item);
      this.selectedEvenOddAndHighLow.splice(index, 1);
      this.evenOddInputMoney.splice(index, 1);
    },
    removeSelectedNumbers(index) {
      this.selectedNumbers.splice(index, 1);
      this.numberInputMoney.splice(index, 1);
      if (this.selectedNumberOptionCounter > 0)
        this.selectedNumberOptionCounter--;
    },

    async print() {},

    async recietOverview() {
      let count = 0;
      let totalMoney = 0;
      this.totalPossibleWin = 0;
      if (
        this.selectedNumbers.length == this.numberInputMoney.length &&
        this.selectedEvenOddAndHighLow.length == this.evenOddInputMoney.length
      ) {
        for (let i = 0; i < this.selectedNumbers.length; i++) {
          if (this.selectedNumbers[i].length == 0) count++;
          if (this.numberInputMoney[i] == "") count++;
        }
        if (count == 0) {
          this.userNumber = Date.now();
          this.gameDate = Date.now();
          var choosenNumbers = "";
          var othersSelected = "";
          for (let i = 0; i < this.selectedNumbers.length; i++) {
            totalMoney = totalMoney + parseFloat(this.numberInputMoney[i]);
            for (let j = 0; j < this.$store.state.odds.length; j++) {
              if (
                this.$store.state.odds[j].choosen_length ==
                this.selectedNumbers[i].length
              ) {
                this.totalPossibleWin =
                  this.totalPossibleWin +
                  (parseFloat(this.numberInputMoney[i]) *
                    parseFloat(
                      this.$store.state.odds[j].odd_value.split(",")[0]
                    )) /
                    10;
              }
            }

            if (choosenNumbers == "") {
              choosenNumbers =
                this.selectedNumbers[i].toString() +
                "*" +
                this.numberInputMoney[i].toString();
            } else
              choosenNumbers =
                choosenNumbers +
                ":" +
                this.selectedNumbers[i].toString() +
                "*" +
                this.numberInputMoney[i].toString();
          }

          for (let j = 0; j < this.selectedEvenOddAndHighLow.length; j++) {
            totalMoney = totalMoney + parseFloat(this.evenOddInputMoney[j]);
            for (let k = 0; k < this.$store.state.odds.length; k++) {
              if (
                this.$store.state.odds[k].choosen_length.toLowerCase() ==
                this.selectedEvenOddAndHighLow[j].toLowerCase()
              ) {
                this.totalPossibleWin =
                  this.totalPossibleWin +
                  parseFloat(this.evenOddInputMoney[j]) *
                    parseFloat(this.$store.state.odds[k].odd_value);
              }
            }

            if (othersSelected == "") {
              othersSelected =
                this.selectedEvenOddAndHighLow[j] +
                "*" +
                this.evenOddInputMoney[j].toString();
            } else
              othersSelected =
                othersSelected +
                ":" +
                this.selectedEvenOddAndHighLow[j] +
                "*" +
                this.evenOddInputMoney[j].toString();
          }

          if (this.partnerBalance.tempo_package >= totalMoney) {
            this.totalInputMoney = totalMoney;
            this.printDialog = true;

            try {
              var ticketResult = await axios({
                method: "POST",
                url: this.$store.state.cashierURL,

                data: {
                  query: `mutation{
               saveTicket(
                game_id:${this.currentGame[0].game_id},
               user_id:"${this.userNumber}",
               cashier_id:${this.$store.state.cashier.cashier_id},
               partner_id:${this.$store.state.cashier.partner_id},
               choosen_numbers: "${choosenNumbers}",
               others: "${othersSelected}",
               ticket_date: "${this.gameDate}",
               tempo_package:${this.partnerBalance.tempo_package - totalMoney}

               )
                {
                      statusMessage

                   }
              }
              `,
                },
              });
              if (
                ticketResult.data.data.saveTicket[0].statusMessage.toString() ==
                "true"
              ) {
                if (choosenNumbers != "") {
                  if (this.$store.state.nubersTicketToAnalyze == "") {
                    this.$store.dispatch(
                      "nubersTicketToAnalyze",
                      choosenNumbers
                    );
                  } else {
                    this.$store.dispatch(
                      "nubersTicketToAnalyze",
                      this.$store.state.nubersTicketToAnalyze +
                        ":" +
                        choosenNumbers
                    );
                  }
                }

                if (othersSelected != "") {
                  if (this.$store.state.othersTicketToAnalyze == "") {
                    this.$store.dispatch(
                      "othersTicketToAnalyze",
                      othersSelected
                    );
                  } else {
                    this.$store.dispatch(
                      "othersTicketToAnalyze",
                      this.$store.state.othersTicketToAnalyze +
                        ":" +
                        othersSelected
                    );
                  }
                }

                var content = this.$refs.printable;
                this.printDialog = false;

                var newWindow = window.open("");

                newWindow.document.write(content.outerHTML);
                setTimeout(function () {
                  newWindow.print();
                  newWindow.close();
                }, 100);
                this.selectedNumbers = [];
                this.selectedEvenOddAndHighLow = [];
                this.evenOddInputMoney = [];
                this.selectedNumberOptionCounter = 0;
                this.numberInputMoney = [];

                await this.getBalance();
                await this.getLastTwoGames();
              } else alert("Ticket not printed, try again");
            } catch (err) {
              alert(err);
            }
          } else alert("Insufficient funds, please deposit to continue");
        } else alert("Please fill all fields and amounts");
      } else alert("Please fill all fields and amounts");
    },
    async getCurrentGame() {
      try {
        var currentGameResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
               getPendingOrPlayingGame(cashier_id:${this.$store.state.cashier.cashier_id},partner_id:${this.$store.state.cashier.partner_id}){
                      game_id
                      game_number
                      drawn_numbers
                      game_date
                      high_low
		                  even_odd
                      game_status

                   }
              }
              `,
          },
        });
        this.currentGame = currentGameResult.data.data.getPendingOrPlayingGame;

        if (this.currentGame.length > 0) {
          this.timeInSeconds = Math.floor(
            300 - (Date.now() - Number(this.currentGame[0].game_date)) / 1000
          );

          this.timePercent =
            0.3334 *
            Math.floor(
              (Date.now() - Number(this.currentGame[0].game_date)) / 1000
            );
        } else {
          this.timePercent = 0;
          this.minutes = "00";
          this.seconds = "00";
        }
      } catch (err) {}
    },

    async getLastTwoGames() {
      //  this.ticketLoading = true;
      try {
        var lastGameResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
                  getLastTwoGames(cashier_id:${this.$store.state.cashier.cashier_id}){
                       game_id

                       game_number
                       drawn_numbers
                       game_date
                       high_low
                       even_odd
                       game_status
                       getTickets{
                         cashier_id
                         ticket_id
                         game_id
                         user_id
                         choosen_numbers
                         others
                         ticket_date
                         winner_award
                         is_cancelled
                       }
                     }


              }
              `,
          },
        });

        this.lastTwoGames = lastGameResult.data.data.getLastTwoGames;
        if (this.lastTwoGames.length > 0) {
          var lastGame = this.lastTwoGames[0].getTickets;
          this.isDuplicated = false;
          for (let j = 0; j < lastGame.length; j++) {
            var ticket = lastGame[j];

            if (j != 0) {
              if (lastGame.length != j + 1) {
                if (
                  (lastGame[j - 1].choosen_numbers == ticket.choosen_numbers &&
                    lastGame[j - 1].others == ticket.others &&
                    lastGame[j - 1].cashier_id == ticket.cashier_id) ||
                  (lastGame[j + 1].choosen_numbers == ticket.choosen_numbers &&
                    lastGame[j + 1].others == ticket.others &&
                    lastGame[j + 1].cashier_id == ticket.cashier_id)
                ) {
                  this.isDuplicated = true;
                }
              } else {
                if (
                  lastGame[j - 1].choosen_numbers == ticket.choosen_numbers &&
                  lastGame[j - 1].others == ticket.others &&
                  lastGame[j - 1].cashier_id == ticket.cashier_id
                ) {
                  this.isDuplicated = true;
                }
              }
            } else {
              if (lastGame.length != j + 1) {
                if (
                  lastGame[j + 1].choosen_numbers == ticket.choosen_numbers &&
                  lastGame[j + 1].others == ticket.others &&
                  lastGame[j + 1].cashier_id == ticket.cashier_id
                ) {
                  this.isDuplicated = true;
                }
              }
            }
          }
        }

        // this.ticketDialog = true;
      } catch (err) {}

      // this.ticketLoading = false;
    },

    async getOdds() {
      try {
        var oddsResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
                getOdds{
                  odds_id
                  choosen_length
                  hits
                  odd_value

                }

              }
              `,
          },
        });

        this.$store.dispatch("odds", oddsResult.data.data.getOdds);
      } catch (err) {}

      try {
        var percentResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
                getPercent{
                  percent_value
                  percent_id
                }

              }
              `,
          },
        });

        this.$store.dispatch("percent", percentResult.data.data.getPercent[0]);
      } catch (err) {}
    },

    async getCommonFeatures() {
      try {
        var commonResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
               getCommonFeatures{
                 common_features_id
                 brand_name
                 phone
                 others
                }


              }
              `,
          },
        });

        this.commonFeatures = commonResult.data.data.getCommonFeatures;
      } catch (err) {
        alert(err);
      }
    },

    async reportTicket() {
      if (this.userIdForReport != "" && this.reportReason != "") {
        this.reportLoading = true;
        try {
          var reportResult = await axios({
            method: "POST",
            url: this.$store.state.cashierURL,

            data: {
              query: `mutation{
               reportTicket(user_id:"${this.userIdForReport}",
              reason: "${this.reportReason}"){
                      statusMessage

                   }
              }
              `,
            },
          });
          if (
            reportResult.data.data.reportTicket[0].statusMessage.toString() ==
            "true"
          ) {
            alert("Reported successfully!");
            this.reportTicketDialog = false;
          } else alert("Some thing went wrong");
        } catch (err) {
          alert(err);
        }
        this.reportLoading = false;
      } else alert("Please fill the fields");
    },

    async getBlockedCashier() {
      try {
        var cashierResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
                getBlockedCashier(cashier_id:${this.$store.state.cashier.cashier_id}){
                  is_active
                }

              }
              `,
          },
        });

        if (!cashierResult.data.data.getBlockedCashier[0].is_active) {
          await this.logout();
          alert("Cashier is blocked");
        }
      } catch (err) {}
    },

    async payWinnerAward() {
      this.payLoading = true;
      try {
        var payResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `mutation{
               payWinnerAward(ticket_id:${this.verifyUserData[0].ticket_id},
               user_id:"${this.verifyUserData[0].user_id}",
                winner_award: ${this.totalWinMoneyForOneUser}){
                      statusMessage

                   }
              }
              `,
          },
        });
        if (
          payResult.data.data.payWinnerAward[0].statusMessage.toString() ==
          "true"
        ) {
          this.winnersDialog = false;
          await this.getBalance();
          this.userIDToVerify = "";
        }
      } catch (err) {
        alert(err);
      }

      this.payLoading = false;
    },
    async cancelDuplicatedTicket(ticket, lastGame, j) {
      let isDuplicated = false;
      if (j != 0) {
        if (lastGame.length != j + 1) {
          if (
            (lastGame[j - 1].choosen_numbers == ticket.choosen_numbers &&
              lastGame[j - 1].others == ticket.others &&
              lastGame[j - 1].cashier_id == ticket.cashier_id) ||
            (lastGame[j + 1].choosen_numbers == ticket.choosen_numbers &&
              lastGame[j + 1].others == ticket.others &&
              lastGame[j + 1].cashier_id == ticket.cashier_id)
          ) {
            isDuplicated = true;
          }
        } else {
          if (
            lastGame[j - 1].choosen_numbers == ticket.choosen_numbers &&
            lastGame[j - 1].others == ticket.others &&
            lastGame[j - 1].cashier_id == ticket.cashier_id
          ) {
            isDuplicated = true;
          }
        }
      } else {
        if (lastGame.length != j + 1) {
          if (
            lastGame[j + 1].choosen_numbers == ticket.choosen_numbers &&
            lastGame[j + 1].others == ticket.others &&
            lastGame[j + 1].cashier_id == ticket.cashier_id
          ) {
            isDuplicated = true;
          }
        }
      }

      if (isDuplicated) {
        this.userIDToCancel = ticket.user_id;

        await this.cancelTicket();
        await this.getMoneyOnHands();
        this.userIDToCancel = "";
        this.ticketDialog = false;
      } else alert("You cannot delete");
    },
    async cancelTicket() {
      if (this.userIDToCancel != "") {
        this.cancelLoading = true;
        try {
          var cancelResult = await axios({
            method: "POST",
            url: this.$store.state.cashierURL,

            data: {
              query: `mutation{
               cancelTicket(
               user_id:"${this.userIDToCancel}"){
                      statusMessage

                   }
              }
              `,
            },
          });
          if (
            cancelResult.data.data.cancelTicket[0].statusMessage.toString() ==
            "true"
          ) {
            this.cancelDialog = false;
            this.userIDToCancel = "";
            alert("Cancelled succesfully!");
            await this.getBalance();
            await this.getLastTwoGames();
          } else alert("You can not cancel the ticket, or already cancelled");
        } catch (err) {
          alert(err);
        }

        this.cancelLoading = false;
      } else alert("Please scan Bar code or enter user id");
    },

    async getBalance() {
      try {
        var partnerBalanceResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
                getPartnerById(partner_id:${this.$store.state.cashier.partner_id}){
                     tempo_package
                     permanent_package
                     partner_balance
                     credit_chance
                     shop_number

                   }

              }
              `,
          },
        });
        this.partnerBalance = partnerBalanceResult.data.data.getPartnerById[0];
      } catch (err) {}
    },

    async verify() {
      if (this.userIDToVerify != "") {
        this.verifyLoading = true;
        await this.getOdds();
        try {
          var verifyResult = await axios({
            method: "POST",
            url: this.$store.state.cashierURL,

            data: {
              query: `{
               verifyUser(user_id:"${this.userIDToVerify}")
                {
                        ticket_id
    game_id
    user_id
    choosen_numbers
    others
    ticket_date
    winner_award

    getGame{
      game_id
      game_number
      drawn_numbers
      game_date
      high_low
      even_odd
      game_status

    }

                   }
              }
              `,
            },
          });
          this.verifyUserData = verifyResult.data.data.verifyUser;
          var totalWinOtherMoney = 0;
          var totalWinMoney = 0;

          if (this.verifyUserData.length > 0) {
            var tickets = this.verifyUserData[0].choosen_numbers.split(":");
            var others = this.verifyUserData[0].others.split(":");
            if (others[0] != "" && this.verifyUserData[0].getGame.length > 0) {
              for (let r = 0; r < others.length; r++) {
                if (
                  this.verifyUserData[0].getGame[0].high_low.toLowerCase() ==
                    others[r].split("*")[0].trim().toLowerCase() ||
                  this.verifyUserData[0].getGame[0].even_odd.toLowerCase() ==
                    others[r].split("*")[0].trim().toLowerCase()
                ) {
                  for (let x = 0; x < this.$store.state.odds.length; x++) {
                    if (
                      this.$store.state.odds[x].choosen_length.toLowerCase() ==
                      others[r].split("*")[0].trim().toLowerCase()
                    ) {
                      totalWinOtherMoney =
                        totalWinOtherMoney +
                        parseFloat(this.$store.state.odds[x].odd_value) *
                          parseFloat(others[r].split("*")[1].trim());
                    }
                  }
                }
              }
            }

            if (tickets[0] != "" && this.verifyUserData[0].getGame.length > 0) {
              for (let i = 0; i < tickets.length; i++) {
                var eachNumber = tickets[i].split("*")[0].split(",");
                var eachAmount = parseFloat(tickets[i].split("*")[1]);

                var matchCount = 0;
                var drownNumbers =
                  this.verifyUserData[0].getGame[0].drawn_numbers.split(",");
                for (let k = 0; k < eachNumber.length; k++) {
                  for (let j = 0; j < drownNumbers.length; j++) {
                    if (drownNumbers[j] == eachNumber[k].trim()) {
                      matchCount++;
                    }
                  }
                }

                for (let x = 0; x < this.$store.state.odds.length; x++) {
                  if (
                    parseInt(this.$store.state.odds[x].choosen_length) ==
                    eachNumber.length
                  ) {
                    var hits = this.$store.state.odds[x].hits.split(",");
                    var possibleWinMoney =
                      this.$store.state.odds[x].odd_value.split(",");

                    for (let y = 0; y < hits.length; y++) {
                      if (matchCount == parseInt(hits[y].trim())) {
                        totalWinMoney =
                          totalWinMoney +
                          parseFloat(possibleWinMoney[y].trim()) * eachAmount;
                      }
                    }
                  }
                }
              }
            }

            this.totalWinMoneyForOneUser =
              totalWinMoney / 10 + totalWinOtherMoney;

            this.verifyDialog = false;
            this.winnersDialog = true;
          } else
            alert(
              "User not exist, game is not over, Ticket is cancelled, Ticket expires or user already paid"
            );
        } catch (err) {
          alert(err);
        }
        this.verifyLoading = false;
        this.userIDToVerify = "";
      } else alert("Please scan Bar code or enter user id");
    },

    async getMoneyOnHands() {
      var date2 = "";
      var date1 = "";

      date2 = Date.now();
      date1 =
        new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;
      try {
        var moneyOnHandsResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
               getMoneyOnHands(cashier_id: ${this.$store.state.cashier.cashier_id}, date1:"${date1}", date2:"${date2}"){
                    choosen_numbers
                    others
                    winner_award
                    is_cancelled

                   }
              }
              `,
          },
        });

        var moneyOnHand = moneyOnHandsResult.data.data.getMoneyOnHands;
        this.totalMoneyOnHand = 0;
        this.totalMoneyPaid = 0;
        this.noTickets = 0;
        this.noPaidTickets = 0;
        this.noCancelledTickets = 0;
        this.noBets = 0;

        //for (let i = 0; i < moneyOnHand.length; i++) {
        for (let t = 0; t < moneyOnHand.length; t++) {
          if (moneyOnHand[t].is_cancelled == 0) {
            var tickets = moneyOnHand[t].choosen_numbers.split(":");
            var others = moneyOnHand[t].others.split(":");
            if (tickets[0] != "") {
              this.noBets = this.noBets + tickets.length;
              for (let j = 0; j < tickets.length; j++) {
                this.totalMoneyOnHand =
                  this.totalMoneyOnHand + parseFloat(tickets[j].split("*")[1]);
              }
            }

            if (others[0] != "") {
              this.noBets = this.noBets + others.length;
              for (let k = 0; k < others.length; k++) {
                this.totalMoneyOnHand =
                  this.totalMoneyOnHand + parseFloat(others[k].split("*")[1]);
              }
            }
          }

          this.totalMoneyPaid =
            this.totalMoneyPaid + moneyOnHand[t].winner_award;
          if (moneyOnHand[t].winner_award != 0)
            this.noPaidTickets = this.noPaidTickets + 1;
          if (moneyOnHand[t].is_cancelled == 1) {
            this.noCancelledTickets = this.noCancelledTickets + 1;
          } else this.noTickets = this.noTickets + 1;
        }
      } catch (err) {}
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    async logout() {
      this.logoutLoading = true;
      try {
        var loggedinResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `mutation{
               loggedInCashier(
                cashier_id:${this.$store.state.cashier.cashier_id},
                is_loggedin:0
             )
                {
                      statusMessage

                   }
              }
              `,
          },
        });
        if (
          loggedinResult.data.data.loggedInCashier[0].statusMessage.toString() ==
          "true"
        ) {
          if (this.logoutDialog) {
            try {
              var cashierReportResult = await axios({
                method: "POST",
                url: this.$store.state.cashierURL,

                data: {
                  query: `mutation{
               addCashierReport(
                cashier_name:"${
                  this.$store.state.cashier.cashier_full_name
                }",              
                loggin_time: "${this.$store.state.cashier.loggin_time}",
          
                logout_time: "${Date.now()}",
                locationCoordinat: "${this.currentLocation}",
                ip_address: "${this.ipAddress}",
                total_tickets: ${this.noTickets},
                paid_tickets: ${this.noPaidTickets},
                cancelled_tickets: ${this.noCancelledTickets},
                number_bets: ${this.noBets},
          
                total_money: ${this.totalMoneyOnHand},
                paid_money: ${this.totalMoneyPaid},
                onhand_money: ${this.totalMoneyOnHand - this.totalMoneyPaid},
                   )
                {
                      statusMessage

                   }
              }
              `,
                },
              });
              if (
                cashierReportResult.data.data.addCashierReport[0].statusMessage.toString() ==
                "true"
              ) {
                var content = this.$refs.printReport;

                var newWindow = window.open("");
                this.logoutDialog = false;

                newWindow.document.write(content.outerHTML);

                newWindow.print();
                newWindow.close();

                this.$router.push({ name: "login" });
                this.$store.dispatch("loginCashier", "");

                this.$router.go();
              } else alert("Some thing went wrong, contact the admin");
            } catch (err) {
              alert(err);
            }
          }

          this.$router.push({ name: "login" });
          this.$store.dispatch("loginCashier", "");

          this.$router.go();
        } else alert("Some thing went wrong, contact the admin");
      } catch (err) {
        alert(err);
      }
      this.logoutLoading = false;
    },

    async getLocation() {
      const results = new Promise(function (resolve, reject) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve(position.coords.latitude + "," + position.coords.longitude);
          },
          (error) => {
            console.log(error.message);
          }
        );
      });
      return results;
    },

    async getIPAddress() {
      const results = new Promise(function (resolve, reject) {
        fetch("https://api.ipify.org?format=json")
          .then((x) => x.json())
          .then(({ ip }) => {
            resolve(ip);
          });
      });
      return results;
    },

    async logoutClicked() {
      this.currentLocation = await this.getLocation();
      this.ipAddress = await this.getIPAddress();
      this.logoutDialog = true;
    },
  },

  async created() {
    if (this.$store.state.cashier != "") {
      this.getCommonFeatures();
      this.getBalance();

      this.getOdds();

      this.time = parseInt(this.$store.state.time);
      this.timeValue = parseFloat(this.$store.state.timePercent);
      this.seconds = "0" + this.seconds;

      setInterval(async () => {
        if (
          this.partnerBalance.tempo_package != 0 &&
          this.partnerBalance.tempo_package <
            2 * this.partnerBalance.permanent_package
        ) {
          this.getBalance();
          this.getBlockedCashier();
          await this.getLastTwoGames();
          await this.getCurrentGame();
          await this.getMoneyOnHands();

          //   this.time = parseInt(this.$store.state.time);
          //  this.timeValue = parseFloat(this.$store.state.timePercent);
          if (this.currentGame.length == 0) {
            //await this.createGame();
            alert(
              "There is no game,Something went wrong, please refresh the page"
            );
          } else {
            if (this.currentGame[0].game_status == "pending") {
              if (this.timeInSeconds > 0) {
                this.isPlaying = false;
                this.timeInSeconds--;
                let hours = Math.floor(this.timeInSeconds / 3600);
                this.minutes = Math.floor(
                  (this.timeInSeconds - hours * 3600) / 60
                ); // get minutes
                this.seconds =
                  this.timeInSeconds - hours * 3600 - this.minutes * 60;

                if (this.minutes < 10) {
                  this.minutes = "0" + this.minutes;
                }
                if (this.seconds < 10) {
                  this.seconds = "0" + this.seconds;
                }

                this.timePercent = this.timePercent + 0.3334;
              } else {
                this.isPlaying = true;
              }
            } else {
              this.isPlaying = true;
            }
          }
        }
      }, 1000);
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#navBar {
  background-color: rgba(27, 42, 61, 255);
}
#numbers,
#selectedNumbers {
  border-radius: 4px;
  background-color: rgba(41, 57, 73, 255);
  color: white;
}
#numbers:hover {
  background-color: rgba(41, 20, 30, 255);
}

#ticketListVertically {
  max-height: 360px;
  overflow-x: hidden;
  overflow-y: auto;
  transform: scaleX(-1);
}

#clearBtn:hover,
#applyToAll:hover {
  background-color: rgba(41, 20, 30, 255);
  color: white;
}
#clearBtn {
  border-radius: 4px;
  background-color: rgb(233, 68, 95);
  color: white;
}
#printBtn {
  background-color: rgba(41, 57, 73, 255);
}

#applyToAll {
  border-radius: 4px;
  background-color: white;
  color: grey;
}
.circle {
  width: 30px;
  height: 30px;

  border-radius: 50%;

  color: #fff;
  text-align: center;
  border: 1px solid white;
  float: center;
  margin-left: 20%;
  padding-top: 2px;
  font-size: 14px;
}

#selectedNum {
  border-radius: 4px;
}
</style>
