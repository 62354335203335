<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="pt-10">
    <v-form @submit.prevent="">
      <v-layout
        row
        wrap
        class="text-center white pa-10 pl-3 pt-5 mt-16"
        id="login"
      >
        <v-flex xs12 class="">
          <p>Keno Game</p>
          <p class="headline">Cashier Login</p>
        </v-flex>
        <v-flex xs12 class="mt-1">
          <v-text-field
            style="font-size: 11px"
            v-model="username"
            :rules="[(v) => !!v || 'Required']"
            label="User name"
            required
            dense
            outlined
            prepend-icon="person"
          ></v-text-field>
        </v-flex>

        <v-flex xs12>
          <v-text-field
            style="font-size: 11px"
            v-model="password"
            :rules="[(v) => !!v || 'Required']"
            label="Password"
            required
            dense
            outlined
            type="password"
            prepend-icon="lock"
          ></v-text-field>
        </v-flex>

        <v-flex xs12>
          <v-btn
            @keyup.enter="cashierLoginLoading"
            type="submit"
            dark
            small
            text
            class="primary"
            @click="login"
            id="loginBtn"
            :loading="cashierLoginLoading"
          >
            <span class="text-capitalize">Login</span>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    username: "",
    password: "",
    currentLocation: "",
    ipAddress: "",
    cashierLoginLoading: false,
  }),

  methods: {
    async login() {
      if (this.password != "" && this.username != "") {
        this.cashierLoginLoading = true;

        try {
          var cashierLoginResult = await axios({
            method: "POST",
            url: this.$store.state.cashierURL,

            data: {
              query: `{
               cashierLogin(user_name:"${this.username}",password:"${this.password}"){
                     cashier_id
                     partner_id
                     cashier_full_name
                     cashier_phone
                     user_name
                     password
                     loggin_time

                   }
              }
              `,
            },
          });

          if (cashierLoginResult.data.data.cashierLogin.length > 0) {
            cashierLoginResult.data.data.cashierLogin[0].loggin_time =
              Date.now();
            try {
              var loggedinResult = await axios({
                method: "POST",
                url: this.$store.state.cashierURL,

                data: {
                  query: `mutation{
               loggedInCashier(
                cashier_id:${cashierLoginResult.data.data.cashierLogin[0].cashier_id},
                is_loggedin:1
             )
                {
                      statusMessage

                   }
              }
              `,
                },
              });
              if (
                loggedinResult.data.data.loggedInCashier[0].statusMessage.toString() ==
                "true"
              ) {
                var cashierLoginTryReportResult = await axios({
                  method: "POST",
                  url: this.$store.state.cashierURL,

                  data: {
                    query: `mutation{
               cashierLoginTryReport(
                user_name:"${this.username}",
                password: "${this.password}",
                locationCoordinat: "${this.currentLocation}",
                ip_address: "${this.ipAddress}",
                login_time: "${Date.now()}",

                   )
                {
                      statusMessage

                   }
              }
              `,
                  },
                });
                this.$store.dispatch(
                  "loginCashier",
                  cashierLoginResult.data.data.cashierLogin[0]
                );
                this.$router.push({ name: "home" });
              } else alert("Some thing went wrong, contact the admin");
            } catch (err) {}
          } else {
            try {
              var cashierLoginTryReportResult = await axios({
                method: "POST",
                url: this.$store.state.cashierURL,

                data: {
                  query: `mutation{
               cashierLoginTryReport(
                user_name:"${this.username}",
                password: "${this.password}",
                locationCoordinat: "${this.currentLocation}",
                ip_address: "${this.ipAddress}",
                login_time: "${Date.now()}",

                   )
                {
                      statusMessage

                   }
              }
              `,
                },
              });
              alert(
                "Please enter correct username and password, or you are blocked  contact the admin"
              );
              // console.log(
              //   cashierLoginTryReportResult.data.data.cashierLoginTryReport[0].statusMessage.toString()
              // );
            } catch (err) {
              alert(err);
            }
          }
        } catch (err) {
          alert(err);
        }

        this.cashierLoginLoading = false;
      } else alert("Please fill all the required fields");
    },
    async getLocation() {
      const results = new Promise(function (resolve, reject) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve(position.coords.latitude + "," + position.coords.longitude);
          },
          (error) => {
            console.log(error.message);
          }
        );
      });
      return results;
    },

    async getIPAddress() {
      const results = new Promise(function (resolve, reject) {
        fetch("https://api.ipify.org?format=json")
          .then((x) => x.json())
          .then(({ ip }) => {
            console.log(ip);
            resolve(ip);
          });
      });
      return results;
    },
  },
  async created() {
    this.currentLocation = await this.getLocation();
    this.ipAddress = await this.getIPAddress();
    // this.$store.dispatch("loginCashier", false);
    if (this.$store.state.cashier != "") this.$router.push({ name: "home" });
  },
};
</script>
<style scoped>
#login {
  max-width: 350px;
  margin: auto;
  border-radius: 5px;
}
</style>
